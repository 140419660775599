import "./Login.css";
import { useState } from "react";
import { requestLogin } from "../DataFetcher";
import { AiOutlineGoogle } from "react-icons/ai";

function Title({ name }) {
    return (
        <div className="TitleLine">
            <hr />
            <span>{name}</span>
            <hr />
        </div>
    );
}

function Status({ status }) {
    switch (status) {
        case "":
            return null;
        case "ok":
            return <span>Login link has been sent to your email</span>;
        case "error":
            return <span className="Warning">Something went wrong. Please make sure that email you enter is correct</span>;
        default:
            return null;
    }
}

export default function Login() {
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    function login() {
        window.location.href = "/login";
    }
    return (
        <div className="LoginRoot">
            <div className="Login">
                <Title name="Sign In" />
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="Button" onClick={login}>
                        <AiOutlineGoogle size="25pt" style={{ marginRight: "6pt" }} />
                        Sign in with Google
                    </div>
                </span>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{/* <Status status={status} /> */}</span>
                <hr />
            </div>
        </div>
    );
}
