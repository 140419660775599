import "./Categories.css";
import { Action } from "../Reducer";

function Item({ value, active, onClick }) {
    return (
        <div onClick={onClick} className={"CategoryItem" + (active ? " active" : "")}>
            {value}
        </div>
    );
}

function Categories({ items, disabledItems, dispatch }) {
    const onClick = (category) => () => dispatch({ type: Action.toggleCategory, category });
    return (
        <div className="Categories">
            {items.map((x) => (
                <Item active={!disabledItems.includes(x)} key={x} value={x} onClick={onClick(x)}></Item>
            ))}
        </div>
    );
}

export default Categories;
