import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { getDateFormater } from "../Formatter";

var getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
        a.push(new Date(d));
    }
    return a;
};

function step(range) {
    return ["1", "day"];
}

function aggregatedExpenses(range, expenses) {
    const s = step(range);
    const result = [];
    var cumulative = 0;
    var i = 0;
    for (var date = range[0].clone(); date < range[1]; date.add(...s)) {
        var amount = 0;
        for (; i < expenses.length && expenses[i].date < date.clone().add(...s); i++) {
            amount += expenses[i].value();
        }
        cumulative += amount;
        result.push({ date: date.clone(), amount, cumulative });
    }
    return result;
}

function Overview({ expenses, range }) {
    const expenses2 = [
        { date: new Date("2023-05-01"), amount: 0, cumulative: 0 },
        { date: new Date("2023-05-02"), amount: 8, cumulative: 8 },
        { date: new Date("2023-05-03"), amount: 0, cumulative: 8 },
        { date: new Date("2023-05-04"), amount: 0, cumulative: 8 },
        { date: new Date("2023-05-05"), amount: 0, cumulative: 8 },
        { date: new Date("2023-05-06"), amount: 0, cumulative: 8 },
        { date: new Date("2023-05-07"), amount: 0, cumulative: 8 },
        { date: new Date("2023-05-08"), amount: 20, cumulative: 28 },
        { date: new Date("2023-05-09"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-10"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-11"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-12"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-13"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-14"), amount: 0, cumulative: 28 },
        { date: new Date("2023-05-15"), amount: 6, cumulative: 34 },
        { date: new Date("2023-05-16"), amount: 10, cumulative: 44 },
        { date: new Date("2023-05-17"), amount: 0, cumulative: 44 },
        { date: new Date("2023-05-18"), amount: 0, cumulative: 44 },
        { date: new Date("2023-05-19"), amount: 0, cumulative: 44 },
        { date: new Date("2023-05-20"), amount: 0, cumulative: 44 },
        { date: new Date("2023-05-21"), amount: 150, cumulative: 194 },
        { date: new Date("2023-05-22"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-23"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-24"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-25"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-26"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-27"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-28"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-29"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-30"), amount: 0, cumulative: 194 },
        { date: new Date("2023-05-31"), amount: 0, cumulative: 194 },
    ];

    expenses = aggregatedExpenses(range, expenses);

    const formatter = getDateFormater(range);

    return (
        <div className="Overview">
            <Chart
                data={{
                    labels: expenses.map((row) => formatter(row.date)),
                    datasets: [
                        {
                            type: "bar",
                            categoryPercentage: 1,
                            barPercentage: 1,
                            borderWidth: 0,
                            label: "Expenses",
                            data: expenses.map((row) => row.amount),
                        },
                        {
                            type: "line",
                            label: "Total",
                            pointStyle: false,
                            data: expenses.map((row) => row.cumulative),
                        },
                    ],
                }}
            />
        </div>
    );
}

export default Overview;
