import "./DateControl.css";

export default function DateControl({ interval, setInterval2, adjust }) {
    return (
        <span className="DateControl">
            <span className="Element NotStable" onClick={() => adjust(-1)}>
                {"<"}
            </span>
            <span className={"Element" + ("week" === interval ? " active" : "")} onClick={() => setInterval2("week")}>
                Week
            </span>
            <span className={"Element" + ("month" === interval ? " active" : "")} onClick={() => setInterval2("month")}>
                Month
            </span>
            <span className={"Element" + ("year" === interval ? " active" : "")} onClick={() => setInterval2("year")}>
                Year
            </span>
            <span className="Element NotStable" onClick={() => adjust(+1)}>
                {">"}
            </span>
        </span>
    );
}
