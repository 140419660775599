export default class TimeData {
    data;
    range;

    constructor(data, range) {
        this.data = data;
        this.range = range;
    }

    contains(range) {
        return this.range[0] <= range[0] && this.range[1] >= range[1];
    }

    contained(range) {
        return range[0] <= this.range[0] && range[1] >= this.range[1];
    }

    equal(range) {
        return this.range[0] === range[0] && this.range[1] === range[1];
    }

    subsetForRange(range, categories) {
        var result = [];
        if (this.contains(range)) {
            if (this.equal(range)) {
                result = this.data;
            }
            const lower = this.data.find((e) => e.time >= range[0]);
            const upper = this.data.find((e) => e.time > range[1]);
            result = this.data.slice(lower, upper);
        }
        if (categories.length > 0) {
            result = result.filter((e) => e.categories.every((c) => !categories.includes(c)));
        }
        return result;
    }
}
