import TimeData from "./TimeData";
import moment from "moment";

export const Action = {
    loadingSucceeded: "loading succeeded",
    setStatus: "set status",
    loadingErrored: "loading errored",
    updateDisplayedRange: "update displayed range",
    toggleCategory: "toggle category",
    setInterval2: "set interval",
    adjustInterval: "adjust interval",
    showNewExpenses: "showNewExpenses",
    hideNewExpenses: "hideNewExpenses",
    showEditExpenses: "showEditExpenses",
    hideEditExpenses: "hideEditExpenses",
    reload: "reload",
};

export const Status = {
    expected: "expected",
    requested: "requested",
    available: "available",
    errored: "errored",
    aborted: "aborted",
};

function rangeFromDate(now, interval) {
    return [now.clone(), now.clone().add("1", interval)];
}

export function initialState() {
    const interval = "week";
    return {
        categories: [],
        hiddenCategories: [],
        expenses: [],
        displayedExpenses: [],
        displayedRange: rangeFromDate(moment().startOf("day"), interval),
        status: Status.available,
        interval: interval,
        reload: 0,
        showNewExpenses: false,
        showEditExpenses: "",
    };
}

export function categoriesFromExpenses(expenses) {
    return [...new Set(expenses.flatMap((e) => e.categories || []))];
}

export const reducer = (prevState, action) => {
    switch (action.type) {
        case Action.loadingSucceeded: {
            const newExpenses = [...action.expenses];
            const newRange = action.range;
            const newData = new TimeData(newExpenses, newRange);
            let categories = categoriesFromExpenses(newExpenses);
            let status = Status.expected;
            const displayedExpenses = newData.subsetForRange(prevState.displayedRange, []);
            if (newData.contains(prevState.displayedRange)) {
                status = Status.available;
                categories = categoriesFromExpenses(displayedExpenses);
            }
            return {
                ...prevState,
                expenses: newExpenses,
                categories,
                hiddenCategories: [],
                displayedExpenses,
            };
        }

        case Action.updateDisplayedRange: {
            const data = new TimeData(prevState.expenses, prevState.range);
            const displayedRange = action.range;
            const displayedExpenses = data.subsetForRange(displayedRange);
            let status = Status.expected;
            let categories = prevState.categories;
            if (data.contains(displayedRange)) {
                status = Status.available;
                categories = categoriesFromExpenses(prevState.expenses);
            }
            return {
                ...prevState,
                displayedExpenses,
                displayedRange,
                status,
                categories,
            };
        }

        case Action.setStatus: {
            return { ...prevState, status: action.status };
        }

        case Action.toggleCategory: {
            var hiddenCategories = prevState.hiddenCategories;
            if (prevState.hiddenCategories.includes(action.category)) {
                hiddenCategories = prevState.hiddenCategories.filter((c) => c !== action.category);
            } else {
                hiddenCategories = [...prevState.hiddenCategories, action.category];
            }
            const newData = new TimeData(prevState.expenses, prevState.displayedRange);
            const displayedExpenses = newData.subsetForRange(prevState.displayedRange, hiddenCategories);
            return { ...prevState, hiddenCategories, displayedExpenses };
        }
        case Action.setInterval2: {
            return {
                ...prevState,
                interval: action.interval,
                displayedRange: rangeFromDate(prevState.displayedRange[0], action.interval),
            };
        }

        case Action.adjustInterval: {
            const newStart = prevState.displayedRange[0].clone();
            if (action.value > 0) {
                newStart.add("1", prevState.interval);
            } else {
                newStart.subtract("1", prevState.interval);
            }
            return { ...prevState, displayedRange: rangeFromDate(newStart, prevState.interval) };
        }

        case Action.hideNewExpenses: {
            return { ...prevState, showNewExpenses: false };
        }
        case Action.showNewExpenses: {
            return { ...prevState, showNewExpenses: true };
        }
        case Action.hideEditExpenses: {
            return { ...prevState, showEditExpenses: "" };
        }
        case Action.showEditExpenses: {
            return { ...prevState, showEditExpenses: action.value };
        }
        case Action.reload: {
            return {
                ...prevState,
                reload: prevState.reload + 1,
                status: Status.expected,
                data: [],
            }; // TODO: reload
        }

        default:
            return prevState;
    }
};
