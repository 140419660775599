import "./App.css";
import Categories from "./views/Categories";
import Overview from "./views/Overview";
import Actions from "./views/Actions";
import Header from "./views/Header";
import Expenses from "./views/Expenses";
import { useEffect, useReducer, useState } from "react";
import { reducer, initialState, Action } from "./Reducer";
import { useExpenses, useUser } from "./DataFetcher";
import DateControl from "./views/DateControl";
import Login from "./views/Login";
import NewExpense from "./views/NewExpense";
import EditExpense from "./views/EditExpense";

function Router() {
    const user = useUser();
    const [promptLogIn, setPromptLogIn] = useState(false);
    useEffect(() => {
        if (user.error) {
            setPromptLogIn(true);
        }
    }, [user.error]);

    if (promptLogIn) {
        return (
            <div className="Container">
                <div className="App">
                    <Header></Header>
                    <Login />
                </div>
            </div>
        );
    }

    if (!user.data) {
        return <div>loading</div>;
    }

    return (
        <div className="Container">
            <div className="App">
                <App user={user.data} />
            </div>
        </div>
    );
}

function App({ user }) {
    const [state, dispatch] = useReducer(reducer, undefined, initialState);

    const expenses = useExpenses(state.displayedRange);

    useEffect(() => {
        dispatch({
            type: Action.loadingSucceeded,
            expenses,
            range: state.displayedRange,
        });
    }, [expenses, state.displayedRange]);

    return (
        <>
            <Header user={user}></Header>
            <DateControl
                interval={state.interval}
                setInterval2={(interval) => dispatch({ type: Action.setInterval2, interval })}
                adjust={(value) => dispatch({ type: Action.adjustInterval, value })}
            />
            <Overview dispatch={dispatch} range={state.displayedRange} expenses={state.displayedExpenses}></Overview>
            <Categories items={state.categories} disabledItems={state.hiddenCategories} dispatch={dispatch}></Categories>
            <hr></hr>
            <Expenses expenses={state.displayedExpenses} range={state.displayedRange} dispatch={dispatch}></Expenses>
            <Actions dispatch={dispatch}></Actions>
            {state.showNewExpenses ? <NewExpense dispatch={dispatch} /> : null}
            {state.showEditExpenses !== "" ? <EditExpense value={state.showEditExpenses} dispatch={dispatch} /> : null}
        </>
    );
}

export default Router;
