import "./Header.css";
import { useFetch } from "usehooks-ts";

function logOut() {
    window.location.href = "/logout";
}

function Header({ user }) {
    return (
        <div className="Header">
            <div className="User" onClick={logOut}>
                {!!user ? user.name : ""}
            </div>
            <div className="Title">DUES</div>
            {/* <div className="Settings">{!!user ? "Settings" : ""}</div> */}
        </div>
    );
}

export default Header;
