import moment from "moment";

function getLocale() {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
}

const MAX_EXPENSES = 1000;

export class Expense {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.currency = props.currency || "EUR";
        this.amount = props.amount;
        this.date = moment(props.date);
        this.categories = props.categories;
    }

    toJson() {
        return JSON.stringify({
            id: this.id,
            name: this.name,
            currency: this.currency,
            amount: this.amount,
            date: this.date.toISOString(),
            categories: this.categories,
        });
    }

    value() {
        switch (this.currency) {
            case "EUR":
            case "USD":
                return Math.floor(this.amount / 100) + (this.amount % 100) / 100;
            default:
                console.warn("Not supported currency", this.currency);
                return this.amount;
        }
    }

    toLocaleString() {
        return new Intl.NumberFormat(getLocale(), {
            style: "currency",
            currency: this.currency,
        }).format(this.value());
    }
}

export class Subscription {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.currency = props.currency;
        this.amount = props.amount;
        this.begin = moment(props.start_time);
        this.end = props.end_time ? moment(props.end_time) : undefined;
        this.interval = props.reocurrency.split(" ");
        this.categories = props.categories;
    }

    toJson() {
        return JSON.stringify({
            id: this.id,
            name: this.name,
            currency: this.currency,
            amount: this.amount,
            start_time: this.begin.toISOString(),
            reocurrency: this.interval.join(" "),
            categories: this.categories,
            ...(this.end ? { end_time: this.end.toISOString() } : {}),
        });
    }

    expenses(begin, end) {
        const result = [];
        const thisend = this.end || end;
        let i = 0;
        for (var date = this.begin.clone(); date < end && date < thisend; date.add(...this.interval)) {
            if (i > MAX_EXPENSES) {
                console.warn("max number of expenses in range reached");
                break;
            }
            i++;
            if (date >= begin) {
                result.push(new Expense({ ...this, date }));
            }
        }
        return result;
    }
}
