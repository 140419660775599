import { useEffect, useState } from "react";
import { Status } from "./Reducer";
import { useFetch } from "usehooks-ts";
import { Expense, Subscription } from "./expenses/expense";
import expenses from "./views/Expenses";
// const URL = "http://localhost:8080/transactions";

export class DataPoint {
    date;
    amount;
    currency;
    title;
    categories;
    expenseId;
    paymentId;
    constructor(arg) {
        this.date = new Date(arg.date);
        this.amount = arg.amount;
        this.currency = arg.currency;
        this.title = arg.title;
        this.categories = arg.categories;
        this.expenseId = arg.expenseId;
        this.paymentId = arg.paymentId;
    }
}

function descriptorFromRange(d) {
    return d.toString();
}

const DEV = false;

export function requestLogin(email, handler) {
    let url = "/login";
    var host = window.location.protocol + "//" + window.location.host;
    let options = { method: "GET" };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    url += "?email=" + encodeURIComponent(email);
    fetch(url, options)
        .then((response) => {
            if (response.ok) {
                handler();
            } else {
                handler(response.statusText);
            }
        })
        .catch(handler);
}

export function useUser() {
    let url = "/me";
    var host = window.location.protocol + "//" + window.location.host;
    let options = { method: "GET" };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    url = new URL(url, host);
    return useFetch(url.toString(), options);
}

export function useExpenses(range) {
    const transactions = useTransactions(range);
    const subscriptions = useSubscriptions(range);
    const [expenses, setExpenses] = useState([]);
    useEffect(() => {
        const expenses = subscriptions.flatMap((s) => s.expenses(...range));
        expenses.push(...transactions);
        expenses.sort((a, b) => a.date > b.date);
        setExpenses(expenses);
    }, [subscriptions, transactions, range]);
    return expenses;
}

export function newTransaction(transaction) {
    var path = "/transaction";
    var host = window.location.protocol + "//" + window.location.host;
    var options = { method: "POST", body: transaction.toJson() };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    return fetch(url.toString(), options);
}

export function newSubscription(subscription) {
    var path = "/subscription";
    var host = window.location.protocol + "//" + window.location.host;
    var options = { method: "POST", body: subscription.toJson() };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    return fetch(url.toString(), options);
}

export function updateTransaction(transaction) {
    var path = `/transaction/${transaction.id}`;
    var host = window.location.protocol + "//" + window.location.host;
    var options = { method: "PATCH", body: transaction.toJson() };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    return fetch(url.toString(), options);
}

export function updateSubscription(subscription) {
    var path = `/subscription/${subscription.id}`;
    var host = window.location.protocol + "//" + window.location.host;
    var options = { method: "PATCH", body: subscription.toJson() };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    return fetch(url.toString(), options);
}

export function useTransactions(range, other) {
    var path = "/transactions";
    var host = window.location.protocol + "//" + window.location.host;
    var options = { method: "GET" };
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    url.searchParams.set("begin", range[0].toISOString());
    url.searchParams.set("end", range[1].toISOString());
    const { data, error } = useFetch(url.toString(), options);
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
        if (data) {
            setTransactions(data.map((t) => new Expense(t)));
        } else {
            setTransactions([]);
        }
    }, [data]);
    return transactions;
}

export function useSubscriptions(range) {
    var path = "/subscriptions";
    var options = { method: "GET" };
    var host = window.location.protocol + "//" + window.location.host;
    if (DEV) {
        host = "http://localhost:8080";
        options["credentials"] = "include";
        options["mode"] = "cors";
    }
    var url = new URL(path, host);
    url.searchParams.set("begin", range[0].toISOString());
    url.searchParams.set("end", range[1].toISOString());
    const { data, error } = useFetch(url.toString(), options);
    const [subscriptions, setSubscriptions] = useState([]);
    useEffect(() => {
        if (data) {
            setSubscriptions(data.map((t) => new Subscription(t)));
        } else {
            setSubscriptions([]);
        }
    }, [data]);
    return subscriptions;
}

export const useDataFetcher = (range, setData, setStatus, cachedData, force = false) => {
    const [loader, setLoader] = useState(null);
    useEffect(() => {
        if (cachedData.contains(range) && !force) {
            return;
        }

        const cleanup = () => {
            if (loader) {
                loader[1].abort();
                setLoader(null);
                setStatus(Status.aborted);
            }
        };

        const descriptor = descriptorFromRange(range);
        if (loader && loader[0] === descriptor) {
            return;
        }
        if (loader) {
            cleanup();
        }

        const controller = new AbortController();
        setLoader([descriptor, controller]);
        setStatus(Status.requested);
        const body = new FormData();
        body.set("from", range[0].toISOString());
        body.set("to", range[1].toISOString());
        fetch(URL, { method: "GET", signal: controller.signal, mode: "cors", credentials: "include" })
            .then((response) => {
                if (response.ok) {
                    response.json().then((expenses) => {
                        setStatus(Status.available);
                        setData(
                            range,
                            expenses.map((e) => new DataPoint(e)),
                        );
                    });
                } else {
                    setStatus(Status.errored);
                }
            })
            .catch((e) => {
                console.warn(e);
                // TODO: retry
                setStatus(Status.errored);
            })
            .finally(() => {
                setLoader(null);
            });
        return cleanup;
    }, [range, force, loader, cachedData, setData, setStatus]);
};
