import "./Actions.css";
import { Action } from "../Reducer";

function Actions({ dispatch }) {
    return (
        <div className="Actions">
            <div className="ActionItem Add" onClick={() => dispatch({ type: Action.showNewExpenses })}>
                +
            </div>
        </div>
    );
}

export default Actions;
