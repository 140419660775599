import "./NewExpense.css";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Action } from "../Reducer";
import moment from "moment";
import { Expense, Subscription } from "../expenses/expense";
import { newTransaction, newSubscription } from "../DataFetcher";

function FullSizeBackground({ children }) {
    return <div className="FullSizeBackground">{children}</div>;
}

export default function NewExpensePopup({ dispatch }) {
    const changed = false;

    const ref = useRef(null);
    const handleClickOutside = () => {
        if (!changed) {
            dispatch({ type: Action.hideNewExpenses });
        }
    };
    useOnClickOutside(ref, handleClickOutside);
    return (
        <FullSizeBackground>
            <div ref={ref} className="Popup">
                <NewExpense
                    onAdded={() => {
                        dispatch({ type: Action.reload });
                        dispatch({ type: Action.hideNewExpenses });
                    }}
                />
            </div>
        </FullSizeBackground>
    );
}

function NewExpense({ onAdded }) {
    const [expense, setExpense] = useState(true);
    const [reocurrencyValue, setReocurrencyValue] = useState(1);
    const [reocurrencyUnit, setReocurrencyUnit] = useState("m");
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [amount, setAmount] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState([]);

    function onSubmit() {
        console.log("submit", expense);
        if (expense) {
            const e = new Expense({
                name: title,
                currency: "EUR",
                amount: Math.ceil(amount * 100),
                date: moment(startDate, "YYYY-MM-DD").startOf("day"),
                categories: tags,
            });
            newTransaction(e)
                .then(() => {
                    onAdded();
                })
                .catch(console.error);
        } else {
            const reocurrency = `${reocurrencyValue} ${reocurrencyUnit}`;
            const s = new Subscription({
                name: title,
                currency: "EUR",
                amount: Math.ceil(amount * 100),
                start_time: moment(startDate, "YYYY-MM-DD").startOf("day"),
                end_time: moment(endDate, "YYYY-MM-DD").endOf("day"),
                reocurrency: reocurrency,
                categories: tags,
            });
            newSubscription(s)
                .then(() => {
                    onAdded();
                })
                .catch(console.error);
        }
    }

    return (
        <div className="NewExpenseGroup">
            <div className="TabGroup">
                <div onClick={(_) => setExpense(true)} className={expense ? "active" : ""}>
                    Expense
                </div>
                <div onClick={(_) => setExpense(false)} className={expense ? "" : "active"}>
                    Subscription
                </div>
            </div>
            <div>
                <h4>Amount</h4>
                <div className="InputWithCurrency">
                    <input
                        className="Currency"
                        type="number"
                        inputMode="decimal"
                        value={amount}
                        autoFocus
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="CurrencyEur">€</div>
                </div>
            </div>
            <div>
                <h4>Title</h4>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div>
                <h4>Categories</h4>
                <input type="text" defaultValue={tags.join(",")} onBlur={(e) => setTags(e.target.value.split(","))} />
            </div>
            {expense ? (
                <div>
                    <h4>Date</h4>
                    <input type="date" value={startDate} onChange={(e) => (console.log(e.target.value), setStartDate(e.target.value))} />
                </div>
            ) : (
                <>
                    <div>
                        <h4>Start Date</h4>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div>
                        <h4>Repeat every</h4>
                        <div>
                            <input
                                type="number"
                                inputMode="decimal"
                                value={reocurrencyValue}
                                onChange={(e) => setReocurrencyValue(e.target.value)}
                            />
                            <select type="select" value={reocurrencyUnit} onChange={(e) => setReocurrencyUnit(e.target.value)}>
                                <option value="d">day</option>
                                <option value="m">month</option>
                                <option value="y">year</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <h4>End Date</h4>
                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                </>
            )}

            <div>
                <h4>Description</h4>
                <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="Submit" onClick={onSubmit}>
                Add
            </div>
        </div>
    );
}
