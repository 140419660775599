import "./NewExpense.css";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Action } from "../Reducer";
import moment from "moment";
import { Expense, Subscription } from "../expenses/expense";
import { updateTransaction, updateSubscription } from "../DataFetcher";

function FullSizeBackground({ children }) {
    return <div className="FullSizeBackground">{children}</div>;
}

export default function EditExpensePopup({ dispatch, value }) {
    const changed = false;

    const ref = useRef(null);
    const handleClickOutside = () => {
        if (!changed) {
            dispatch({ type: Action.hideEditExpenses });
        }
    };
    useOnClickOutside(ref, handleClickOutside);
    return (
        <FullSizeBackground>
            <div ref={ref} className="Popup">
                <EditExpense
                    value={value}
                    onSaved={() => {
                        dispatch({ type: Action.reload });
                        dispatch({ type: Action.hideEditExpenses });
                    }}
                />
            </div>
        </FullSizeBackground>
    );
}

function EditExpense({ onSaved, value }) {
    const [expense, setExpense] = useState(value["reocurrency"] === undefined);
    const [reocurrencyValue, setReocurrencyValue] = useState(expense ? "1" : value.interval[0]);
    const [reocurrencyUnit, setReocurrencyUnit] = useState(expense ? "m" : value.interval[1]);
    const [startDate, setStartDate] = useState(expense ? value.date : value.startDate);
    const [endDate, setEndDate] = useState((expense ? moment() : value.endDate).format("YYYY-MM-DD"));
    const [amount, setAmount] = useState(value.value());
    const [title, setTitle] = useState(value.name);
    const [description, setDescription] = useState(value.description);
    const [tags, setTags] = useState([...value.categories]);

    function onSubmit() {
        console.log("submit", expense);
        if (expense) {
            const e = new Expense({
                id: value.id,
                name: title,
                currency: "EUR",
                amount: Math.ceil(amount * 100),
                date: startDate,
                categories: tags,
            });
            updateTransaction(e)
                .then(() => {
                    onSaved();
                })
                .catch(console.error);
        } else {
            const reocurrency = `${reocurrencyValue} ${reocurrencyUnit}`;
            const s = new Subscription({
                id: value.id,
                name: title,
                currency: "EUR",
                amount: Math.ceil(amount * 100),
                begin: startDate,
                end: endDate,
                reocurrency: reocurrency,
                categories: tags,
            });
            updateSubscription(s)
                .then(() => {
                    onSaved();
                })
                .catch(console.error);
        }
    }

    return (
        <div className="NewExpenseGroup">
            <div className="TabGroup">
                <div onClick={(_) => setExpense(true)} className={expense ? "active" : ""}>
                    Expense
                </div>
                <div onClick={(_) => setExpense(false)} className={expense ? "" : "active"}>
                    Subscription
                </div>
            </div>
            <div>
                <h4>Amount</h4>
                <div className="InputWithCurrency">
                    <input
                        className="Currency"
                        type="number"
                        inputMode="decimal"
                        value={amount}
                        autoFocus
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="CurrencyEur">€</div>
                </div>
            </div>
            <div>
                <h4>Title</h4>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div>
                <h4>Categories</h4>
                <input type="text" defaultValue={tags.join(",")} onBlur={(e) => setTags(e.target.value.split(","))} />
            </div>
            {expense ? (
                <div>
                    <h4>Date</h4>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
            ) : (
                <>
                    <div>
                        <h4>Start Date</h4>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div>
                        <h4>Repeat every</h4>
                        <div>
                            <input
                                type="number"
                                inputMode="decimal"
                                value={reocurrencyValue}
                                onChange={(e) => setReocurrencyValue(e.target.value)}
                            />
                            <select type="select" value={reocurrencyUnit} onChange={(e) => setReocurrencyUnit(e.target.value)}>
                                <option value="d">day</option>
                                <option value="m">month</option>
                                <option value="y">year</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <h4>End Date</h4>
                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                </>
            )}

            <div>
                <h4>Description</h4>
                <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="Submit" onClick={onSubmit}>
                Save
            </div>
        </div>
    );
}
