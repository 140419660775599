import { getDateFormater } from "../Formatter";
import { Action } from "../Reducer";
import "./Expenses.css";

function Item({ value, upcoming, formatter, onClick }) {
    return (
        <div className={"ExpensesItem" + (upcoming ? " upcoming" : "")} onClick={onClick}>
            {formatter(value.date)}: {value.name} {value.toLocaleString()}
        </div>
    );
}

function Expenses({ expenses, range, dispatch }) {
    const now = Date.now();
    const formatter = getDateFormater(range);
    const [previous, upcoming] = expenses.reduce(
        (a, val) => {
            (val.date < now ? a[0] : a[1]).push(val);
            return a;
        },
        [[], []],
    );
    return (
        <div className="Expenses">
            {previous.length > 0 ? <div>Past payments</div> : null}
            {previous.map((x, i) => (
                <Item key={i} value={x} formatter={formatter} onClick={() => dispatch({ type: Action.showEditExpenses, value: x })} />
            ))}
            {upcoming.length > 0 ? <div>Upcoming payments</div> : null}
            {upcoming.map((x, i) => (
                <Item
                    upcoming
                    key={i}
                    value={x}
                    formatter={formatter}
                    onClick={() => dispatch({ type: Action.showEditExpenses, value: x })}
                />
            ))}
        </div>
    );
}

export default Expenses;
